import React from "react";
import {
  SEO,
  PageLayout,
  Image,
  SocialMediaV2,
  MultipleComponentIterator,
  InternalLink,
  FadeReveal,
  MarkdownContent,
  ServicesList,
  HoursOfOperation,
  Location,
  ContactForm,
} from "@bluefin/components";
import { Grid, Button, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessMenuSchedule,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuItem,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} subfooter={false} className={"home-page"}>
          <Grid
            className={"default-hero"}
            stackable={true}
            textAlign={"center"}
          >
            <Grid.Column width={8} className={"hero-heading"}>
              <Image
                centered={true}
                src={fishermanBusiness.gatsbyLogo.childImageSharp}
              />
              <SocialMediaV2 className={"social-media-container"}>
                <MultipleComponentIterator
                  components={[
                    {
                      component: <SocialMediaV2.Platform />,
                      propMap: { key: "_id", url: "link", platform: "type" },
                      children: [
                        {
                          component: <SocialMediaV2.Icon />,
                          propMap: { icon: "type" },
                        },
                      ],
                    },
                  ]}
                />
              </SocialMediaV2>
              <div className={"ctas-container"}>
                <Button basic={true} to={"#image-gallery"} as={InternalLink}>
                  See Gallery
                </Button>
              </div>
            </Grid.Column>
            <Grid.Column width={8} className={"hero-image"}>
              <Image
                src={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                  numToSelect: 1,
                })}
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            id={"about"}
            className={"component-section-container about-section"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={8}>
              <FadeReveal duration={1250} triggerOnce={true}>
                <Header
                  as={"h2"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "about_header_text",
                    defaultValue: "About",
                  })}
                />
                <MarkdownContent
                  className={"about-section-description"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "about_description_markdown",
                  })}
                />
              </FadeReveal>
            </Grid.Column>
          </Grid>
          <div
            className={"component-section-container services-section"}
            id={"services"}
          >
            <Header
              as={"h2"}
              children={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "services_header_text",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "services_description_markdown",
              })}
            />
            <div className={"services-list-container"}>
              <FadeReveal duration={1250} triggerOnce={true}>
                <Image
                  className={"services-section-image"}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentIdentifier: "services_section_image",
                    numToSelect: 1,
                  })}
                />
              </FadeReveal>
              <ServicesList
                defaultAllOpen={false}
                priceDisplayType={"range"}
                catalog={{
                  schedules: allFishermanBusinessMenuSchedule.nodes,
                  categories: allFishermanBusinessMenuCategory.nodes,
                  items: allFishermanBusinessMenuItem.nodes,
                }}
                animated={true}
              />
            </div>
          </div>
          <div
            className={"image-gallery-section component-section-container"}
            id={"image-gallery"}
          >
            <div
              className={"elfsight-app-f75a17af-4928-47f1-80a8-b6641665cf1d"}
              data-elfsight-app-lazy={true}
            />
          </div>
          <div className={"component-section-container location-section"}>
            <div className={"location-header-container"}>
              <Header
                as={"h2"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "location_section_header",
                  defaultValue: "Visit us",
                })}
              />
              <MarkdownContent
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "location_section_description",
                })}
              />
              <SocialMediaV2>
                <MultipleComponentIterator
                  components={[
                    {
                      component: <SocialMediaV2.Platform />,
                      propMap: { key: "_id", url: "link", platform: "type" },
                      children: [
                        {
                          component: <SocialMediaV2.Icon />,
                          propMap: { icon: "type" },
                        },
                      ],
                    },
                  ]}
                />
              </SocialMediaV2>
            </div>
            <Grid
              stackable={true}
              verticalAlign={"middle"}
              textAlign={"center"}
              centered={true}
              column={2}
            >
              <Grid.Row stretched={true}>
                <Grid.Column width={8} textAlign={"center"}>
                  <div>
                    <Header as={"h3"} content={"Hours"} />
                    <HoursOfOperation
                      hours={fishermanBusiness.primaryLocation.hours}
                      displayOption={"grouped"}
                      enhancedLabels={{
                        openingSoon: "Opening soon",
                        open: "Open now",
                      }}
                    />
                  </div>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <div>
                    <Header as={"h3"} content={"Address"} />
                    <Location
                      businessName={fishermanBusiness.name}
                      address={{
                        street: fishermanBusiness.primaryLocation.street,
                        city: fishermanBusiness.primaryLocation.city,
                        state: fishermanBusiness.primaryLocation.state,
                        zip: fishermanBusiness.primaryLocation.zipCode,
                      }}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          <div className={"component-section-container contact-section"}>
            <div className={"contact-header-container"}>
              <Header
                as={"h2"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "contact_header_text",
                  defaultValue: "Contact Us",
                })}
              />
            </div>
            <div className={"contact-form-container"}>
              <ContactForm
                buttonText={"Submit"}
                centeredHeaders={false}
                hideOptIn={false}
                className={""}
                fluidButton={false}
                header={
                  <MarkdownContent
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "contact_form_description_markdown",
                    })}
                  />
                }
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={false}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
                event={{ category: "Contact", action: "Submit Inquiry" }}
              />
            </div>
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      gatsbyLogo {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 160)
        }
      }
      primaryLocation {
        hours {
          day
          open
          close
          label
        }
        street
        city
        state
        zipCode
        _id
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          _id
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
    allFishermanBusinessMenuSchedule {
      nodes {
        categories
      }
    }
    allFishermanBusinessMenuCategory(sort: { order: ASC, fields: order }) {
      nodes {
        items
        description
        name
        _id
        interactions {
          _id
          behaviorType
          displayType
          enabled
          url
          style
          label
        }
      }
    }
    allFishermanBusinessMenuItem(
      sort: { order: ASC, fields: order }
      filter: { visible: { eq: true } }
    ) {
      nodes {
        modifierSets
        description
        _id
        name
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        variations {
          _id
          menuItem
          name
          order
          price
        }
        annotations {
          likeCount
        }
        visible
        available
        interactions {
          _id
          behaviorType
          displayType
          enabled
          url
          style
          label
        }
      }
    }
  }
`;
